import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Alert, Container, Stack, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { updateFlightCampaign } from '~/services/FlightsService';

import { useFlightCampaign } from '../hooks/useFlightCampaign';
import { useFlightCampaignDeals } from '../hooks/useFlightCampaignDeals';

import { FlightsCampaignsDealsTable } from './FlightsCampaignsDealsTable';
import { FlightsCampaignsForm, OnSaveFormData } from './FlightsCampaignsForm';

export default function FlightsCampaignsEdit() {
  const { id } = useParams<{ id: string }>();
  const { data, error, loading, revalidate } = useFlightCampaign(id);
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<string | undefined>();
  const { data: deals, loading: loadingCampaignDeals, error: errorCampaignDeals } = useFlightCampaignDeals(id);

  const handleUpdate = useCallback(
    async (data: OnSaveFormData) => {
      setUpdateError(undefined);
      setUpdating(true);

      try {
        await updateFlightCampaign(id, {
          brand: data.brand.join(','),
          type: data.type,
          title: data.title,
          region: data.region,
          header: data.header,
          description: data.description,
          start_date: data.startDate,
          end_date: data.endDate,
          hero_image_public_id: data.heroImage,
          terms: data.terms,
          airline_code: data.airlineCode,
          price: parseFloat(data.price),
          fare_class: data.fareClass,
          fare_type: data.fareType,
        });

        revalidate();
        enqueueSnackbar('Campaign was updated', { variant: 'success' });
      } catch {
        setUpdateError('Something went wrong, please try again');
      }

      setUpdating(false);
    },
    [id, revalidate, enqueueSnackbar],
  );

  const showForm = !error && !loading && data;

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit Campaign" backButton />

      <Stack gap={6}>
        {error && <Alert severity="error">Something went wrong, please try again</Alert>}

        {showForm && (
          <FlightsCampaignsForm
            onSubmit={handleUpdate}
            loading={updating}
            error={updateError}
            initialValues={{
              brand: data.brand.split(','),
              description: data.description,
              endDate: data.end_date,
              startDate: data.start_date,
              header: data.header,
              heroImage: data.hero_image_public_id,
              region: data.region,
              title: data.title,
              terms: data.terms,
              type: data.type,
              airlineCode: data.airline_code,
              price: data.price.toString(),
              fareClass: data.fare_class,
              fareType: data.fare_type,
            }}
          />
        )}

        <Stack gap={2}>
          <Typography variant="h5" fontWeight={600}>
            Camapaign linked Deals
          </Typography>

          <FlightsCampaignsDealsTable data={deals} error={errorCampaignDeals} loading={loadingCampaignDeals} />
        </Stack>
      </Stack>
    </Container>
  );
}
