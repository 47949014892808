import { ROOM_RATE_LAST_MINUTE_TYPE, ROOM_RATE_LPC_TYPE } from '~/consts/ratePlans';

import ReservationService from '../../../../../services/ReservationService';
import {
  COPY_OPERATION_LEAD_DAYS,
  COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY,
  COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY,
  COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM,
  COPY_OPTIONS_MODE_ALL_RATES,
  COPY_OPTIONS_MODE_ALL_ROOM_TYPES,
} from '../../constants';

const NO_RATES_MESSAGE = 'no room rates found for this option!';
const NO_LEAD_DAYS_MESSAGE = 'no lead days found to copy!';

type CopyLeadDaysMode =
  | typeof COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM
  | typeof COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY
  | typeof COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM
  | typeof COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY
  | typeof COPY_OPTIONS_MODE_ALL_ROOM_TYPES
  | typeof COPY_OPTIONS_MODE_ALL_RATES;

interface CopyLeadDaysParams {
  mode: CopyLeadDaysMode;
  propertyId: string;
  roomType: App.RoomType;
  roomRate: App.RoomRate;
  propertyRoomTypes: Array<App.RoomType>;
  leadDays: Array<App.RoomRateLeadDay>;
}

async function copyLeadDays(params: CopyLeadDaysParams) {
  const { mode } = params;
  switch (mode) {
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_ROOM:
      await copyRatesForRoomAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;
    case COPY_OPTIONS_MODE_ALL_LPC_FOR_PROPERTY:
      await copyRatesForPropertyAndProductType(params, ROOM_RATE_LPC_TYPE);
      break;

    case COPY_OPTIONS_MODE_ALL_LM_FOR_ROOM:
      await copyRatesForRoomAndProductType(params, ROOM_RATE_LAST_MINUTE_TYPE);
      break;
    case COPY_OPTIONS_MODE_ALL_LM_FOR_PROPERTY:
      await copyRatesForPropertyAndProductType(params, ROOM_RATE_LAST_MINUTE_TYPE);
      break;

    case COPY_OPTIONS_MODE_ALL_ROOM_TYPES:
      await copyToRoomTypes(params);
      break;
    case COPY_OPTIONS_MODE_ALL_RATES:
    default:
      await copyToRates(params);
      break;
  }
}
const copyRatesForRoomAndProductType = async (params: CopyLeadDaysParams, productType: string) => {
  const { leadDays, roomType, roomRate, propertyId } = params;

  const roomRates = roomType.room_rates.filter(
    (roomTypeRoomRate) =>
      roomTypeRoomRate.id !== roomRate.id && roomTypeRoomRate.rate_plan.product_type === productType,
  );

  try {
    await createLeadDaysInBulk(roomRates, leadDays, propertyId, roomType.id);
  } catch (e) {
    throw new Error(`Error while copying room rate lead days under this room: ${e.message}`);
  }
};

const copyRatesForPropertyAndProductType = async (params: CopyLeadDaysParams, productType: string) => {
  const { propertyRoomTypes, roomType, roomRate, leadDays, propertyId } = params;

  const propertyRoomRates = propertyRoomTypes
    .map((_roomType) => {
      return _roomType.room_rates
        .filter((_roomRate) => _roomRate.id !== roomRate.id && _roomRate.rate_plan.product_type === productType)
        .map((rate) => {
          return {
            id: rate.id,
            roomTypeId: _roomType.id,
            rate_plan: null,
          };
        });
    })
    .flat();

  try {
    await createLeadDaysInBulk(propertyRoomRates, leadDays, propertyId, roomType.id);
  } catch (e) {
    throw new Error(`Error while copying room rate lead days over room types: ${e.message}`);
  }
};

const copyToRoomTypes = async (params: CopyLeadDaysParams) => {
  const { propertyRoomTypes, roomType, roomRate, leadDays, propertyId } = params;

  const roomTypes = propertyRoomTypes.filter((_roomType) => {
    return _roomType.id !== roomType.id;
  });

  const ratePlanId = roomRate.rate_plan.id;

  try {
    await Promise.all(
      roomTypes.map(async (_roomType) => {
        let roomRateId;
        for (const _roomRate of _roomType.room_rates) {
          if (_roomRate.rate_plan.id === ratePlanId) {
            roomRateId = _roomRate.id;
            break;
          }
        }

        if (roomRateId) {
          const payload = { lead_days: leadDays };

          await ReservationService.createOperationInBulk(
            payload,
            propertyId,
            _roomType.id,
            roomRateId,
            COPY_OPERATION_LEAD_DAYS,
          );
        }
      }),
    );
  } catch (e) {
    throw new Error(`Error while copying extra guest inclusions over room types: ${e.message}`);
  }
};

const copyToRates = async (params) => {
  const { propertyId, leadDays, roomType, roomRate } = params;

  const roomRates = roomType.room_rates.filter((roomTypeRates) => roomTypeRates.id !== roomRate.id);

  try {
    await createLeadDaysInBulk(roomRates, leadDays, propertyId, roomType.id);
  } catch (e) {
    throw new Error(`Error while copying room rate inclusions under this room: ${e.message}`);
  }
};

const validate = (rates, leadDays) => {
  if (!rates.length) {
    throw new Error(NO_RATES_MESSAGE);
  }
  if (!leadDays.length) {
    throw new Error(NO_LEAD_DAYS_MESSAGE);
  }
};

async function createLeadDaysInBulk(roomRates, leadDays, propertyId, roomTypeId) {
  validate(roomRates, leadDays);

  const payload = { lead_days: leadDays };

  await Promise.all(
    roomRates.map(async (roomRate) => {
      await ReservationService.createOperationInBulk(
        payload,
        propertyId,
        roomTypeId,
        roomRate.id,
        COPY_OPERATION_LEAD_DAYS,
      );
    }),
  );
}

export default copyLeadDays;
