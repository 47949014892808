import React, { useMemo } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material';

import { FailedEmailRecord } from '~/services/NotificationProxyService';

import { Code } from '../Common/Code';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedRecord: FailedEmailRecord | null;
  handleResendEmail: (data: { id: string }) => void;
}

function FailedEmailModel({ open, handleClose, selectedRecord, handleResendEmail }: Props) {
  const payload = useMemo(() => {
    return JSON.stringify(selectedRecord?.payload || {}, null, 2);
  }, [selectedRecord?.payload]);

  const reason = useMemo(() => {
    return JSON.stringify(selectedRecord?.reason || {}, null, 2);
  }, [selectedRecord?.reason]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        View Details
      </DialogTitle>
      <DialogContent dividers>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          {[
            { label: 'ID', key: 'id' },
            { label: 'Attempt Count', key: 'attempt_count' },
            { label: 'First Occurred At', key: 'first_occurred_at', isDate: true },
            { label: 'Last Occurred At', key: 'last_occurred_at', isDate: true },
            { label: 'HTTP Path', key: 'http_path' },
          ].map(({ label, key, isDate }) => (
            <ListItem key={key} sx={{ display: 'list-item', ml: -1, pl: 0 }}>
              <Typography variant="body1" component="span">
                <Typography variant="body1" component="span" fontWeight="bold">
                  {label}:&nbsp;
                </Typography>
                {isDate ? new Date(selectedRecord?.[key]).toLocaleString() : selectedRecord?.[key]}
              </Typography>
            </ListItem>
          ))}
          <ListItem key="payload" sx={{ display: 'list-item', ml: -1, pl: 0 }}>
            <Typography variant="body1" component="div">
              <Typography variant="body1" component="div" fontWeight="bold">
                Payload:&nbsp;
              </Typography>
              <Code value={payload} lang="json" style={{ maxHeight: '200px', overflow: 'auto' }} />
            </Typography>
          </ListItem>
          <ListItem key="reason" sx={{ display: 'list-item', ml: -1, pl: 0 }}>
            <Typography variant="body1" component="div">
              <Typography variant="body1" component="div" fontWeight="bold">
                Reason:&nbsp;
              </Typography>
              <Code value={reason} lang="json" />
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          onClick={() => handleResendEmail({ id: selectedRecord?.id })}
          disabled={!!selectedRecord?.successful_sent_at}
        >
          Resend Email
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FailedEmailModel;
